import { SEARCH_USER_SUCCESS, GET_FEEDS, GET_PRODUCT_COMMENTS, ADD_COMMENTS, 
    LIKE_FEED_PRODUCT, OTHER_USER_DATA, SELLER_ALL_PRODUCT, ADD_TO_WISHLIST, 
    GET_NOTIFICATION, READ_NOTIFICATION, GET_MY_SALES, UPDATE_ORDER_STATUS, FOLLOW_UNFOLLOW_USER, GET_ADDRESS,
    ADD_ADDRESS, DELETE_ADDRESS, UPDATE_ADDRESS, GET_WISHLIST, ORDER_PLACE, GET_MY_WALLET, GET_FEEDS_FILTER, search_Name_success, SEND_CHAT_NOTIFICATION, SINGLE_PRODUCT_BUY } from "../actions/types";

const INIT_STATE = {
    single_product_buy:"",
    feed_Search_Name :"",
    cross_icon_show:false,
    get_feed_filterData :[],
    search_userData: null,
    get_feed: null,
    get_product_comment_data: null,
    add_comment: null,
    like_feed: null,
    other_user_data: null,
    seller_all_product: null,
    add_wishlist: null,
    get_notification: null,
    read_notification: null,
    send_chat_notification: null,
    get_sales: null,
    update_order_status: null,
    follow_unfollow: null,
    get_address: null,
    add_address: null,
    delete_address: null,
    update_address: null,
    get_wishlist:null,
    order_place:null,
    get_wallet:null,

   
};

export default (state = INIT_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case SEARCH_USER_SUCCESS:
            return {
                ...state,
                search_userData: payload?.data,
            }
        case GET_FEEDS:
            return {
                ...state,
                get_feed: payload,
            }
        case GET_FEEDS_FILTER:
            return {
                ...state,
                get_feed_filterData: payload,
            }
        case GET_PRODUCT_COMMENTS:
            return {
                ...state,
                get_product_comment_data: payload,
            }
        case ADD_COMMENTS:
            return {
                ...state,
                add_comment: payload,
            }
        case LIKE_FEED_PRODUCT:
            return {
                ...state,
                like_feed: payload,
            }
        case OTHER_USER_DATA:
            return {
                ...state,
                other_user_data: payload,
            }
        case SELLER_ALL_PRODUCT:
            return {
                ...state,
                seller_all_product: payload,
            }
        case ADD_TO_WISHLIST:
            return {
                ...state,
                add_wishlist: payload,
            }
        case GET_NOTIFICATION:
            return {
                ...state,
                get_notification: payload,
            }
        case READ_NOTIFICATION:
            return {
                ...state,
                read_notification: payload,
            }
        case GET_MY_SALES:
            return {
                ...state,
                get_sales: payload,
            }
        case UPDATE_ORDER_STATUS:
            return {
                ...state,
                update_order_status: payload,
            }
        case FOLLOW_UNFOLLOW_USER:
            return {
                ...state,
                follow_unfollow: payload,
            }
        case GET_ADDRESS:
            return {
                ...state,
                get_address: payload,
            }
        case ADD_ADDRESS:
            return {
                ...state,
                add_address: payload,
            }
        case DELETE_ADDRESS:
            return {
                ...state,
                delete_address: payload,
            }
        case UPDATE_ADDRESS:
            return {
                ...state,
                update_address: payload,
            }
        case GET_WISHLIST:
            return {
                ...state,
                get_wishlist: payload,
            }
        case ORDER_PLACE:
            return {
                ...state,
                order_place: payload,
            }
        case SINGLE_PRODUCT_BUY:
            return {
                ...state,
                single_product_buy: payload,
            }
        case SEND_CHAT_NOTIFICATION:
            return {
                ...state,
                send_chat_notification: payload,
            }
        case GET_MY_WALLET:
            return {
                ...state,
                get_wallet: payload,
            }
        case search_Name_success:
            return {
                ...state,
                feed_Search_Name: payload.search_Name,
                cross_icon_show:payload.cross_Show
            }
        default:
            return state;
    }
};