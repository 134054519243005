import { LOGIN_SUCCESS, REGISTER_SUCCESS, VERIFY_OTP, FORGOT_PASSWORD, RESET_PASSWORD, RESEND_OTP, CHANGE_PASSWORD, LOGOUT } from "../actions/types";

const INIT_STATE = {
  login_success: null,
  register: null,
  forget: null,
  change_pass: null,
  verification: null,
  reset_pass: null,
  resend_pass: null,
  logout: null,
};

export default (state = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_SUCCESS:
      if (payload.status) {
        return {
          ...state,
          token: payload.data.token,
          user_data: payload.data.data,
          login_success: payload,
        };
      }
      return {
        ...state,
        login_success: payload,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        register: payload,
      }; case FORGOT_PASSWORD:
      return {
        ...state,
        forget: payload,
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
        change_pass: payload,
      };
    case VERIFY_OTP:
      return {
        ...state,
        verification: payload,
        login_success: payload,
        token: payload?.data?.token,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        reset_pass: payload,
      };
    case RESEND_OTP:
      return {
        ...state,
        resend_pass: payload,
      };
    case LOGOUT:
      return {
        ...state,
        logout: payload,
      };
    default:
      return state;
  }
};