import { ADDL_PRODUCT_SUCCESS, SIZE_AVAILABLE_SUCCESS , ORDER_PAYMENT_STATUS, UPDATE_PRODUCT_SUCCESS, DELTE_IMAGE_SUCCESS } from "../actions/types";

const INIT_STATE = {
    avail_size_data :null,
    add_to_sell_data:null,
    order_pay_status:null,
    update_sell_data:null,
    deleteImg_data:null
};


export default (state = INIT_STATE, action) => {
    const { type, payload } = action;
    // console.log("puooi$$$$", payload)
    switch (type) {
        case SIZE_AVAILABLE_SUCCESS:

            return {
                ...state,
                avail_size_data: payload?.data,
            }
        case ADDL_PRODUCT_SUCCESS:
            return {
                ...state,
                avail_size_data: payload?.data,
            }
        case UPDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                update_sell_data: payload?.data,
            }
        case DELTE_IMAGE_SUCCESS:
            return {
                ...state,
               deleteImg_data: payload?.data,
            }
        case ORDER_PAYMENT_STATUS:
            return {
                ...state,
                order_pay_status: payload?.data,
            }
        default:
            return state;
    }
};