//auth
export const LOGOUT = "LOGOUT";
export const RESEND_OTP = "RESEND_OTP";
export const VERIFY_OTP = "VERIFY_OTP";
export const OTP_SUCCESS = "OTP_SUCCESS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const RESET_PASSWORD = "RESET_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD_SUCCESS";

//Profile
export const USER_TOKEN = "USER_TOKEN";
export const FAQ_SUCCESS = "FAQ_SUCCESS"
export const DELETE_USER = "DELETE_USER"
export const CHANGE_EMAIL = "CHANGE_EMAIL";
export const LINK_ACCOUNT = "LINK_ACCOUNT";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const MY_CART_SUCCESS = "MY_CART_SUCCESS"
export const CONTACT_SUCCESS = "CONTACT_SUCCESS"
export const MY_ORDER_SUCCESS = "MY_ORDER_SUCCESS"
export const GET_USER_PROFILE = "GET_USER_PROFILE"
export const MY_CLOSET_SUCCESS = "MY_CLOSET_SUCCESS"
export const SEARCH_USER_SUCCESS = "SEARCH_USER_SUCCESS"
export const DELETE_CART_SUCCESS = "DELETE_CART_SUCCESS"
export const GIVE_FEEDBACK_SUCCESS = "GIVE_FEEDBACK_SUCCESS"
export const PROFILE_TERMS_SUCCESS = "Profile_TermPolicyAbout_SUCCESS"
export const GET_MY_ORDER_DETAIL_SUCCESS = "GET_MY_ORDER_DETAIL_SUCCESS"

// Other User data
export const OTHER_USER_DATA = "OTHER_USER_DATA";

// Feed & Sell
export const GET_FEEDS = "GET_FEEDS";
export const ADD_ADDRESS = "ADD_ADDRESS";
export const GET_ADDRESS = "GET_ADDRESS";
export const ORDER_PLACE = "ORDER_PLACE";
export const SINGLE_PRODUCT_BUY = "SINGLE_PRODUCT_BUY"
export const GET_WISHLIST = "GET_WISHLIST";
export const GET_MY_SALES = "GET_MY_SALES";
export const ADD_COMMENTS = "ADD_COMMENTS";
export const GET_MY_WALLET = "GET_MY_WALLET";
export const DELETE_ADDRESS = "DELETE_ADDRESS";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const READ_NOTIFICATION = "READ_NOTIFICATION";
export const LIKE_FEED_PRODUCT = "LIKE_FEED_PRODUCT";
export const SELLER_ALL_PRODUCT = "SELLER_ALL_PRODUCT";
export const UPDATE_ORDER_STATUS = "UPDATE_ORDER_STATUS";
export const FOLLOW_UNFOLLOW_USER = "FOLLOW_UNFOLLOW_USER";
export const ORDER_PAYMENT_STATUS = "ORDER_PAYMENT_STATUS";
export const GET_PRODUCT_COMMENTS = "GET_PRODUCT_COMMENTS";
export const SEND_CHAT_NOTIFICATION = "SEND_CHAT_NOTIFICATION";

///// Charity
export const ADD_CART_SUCCESS = "ADD_CART_SUCCESS"
export const CHARITY_LIST_SUCCESS = "CHARITY_LIST_SUCCESS"
export const SELLER_DETAIL_SUCCESS = "SELLER_DETAIL_SUCCESS"
export const CHARITY_CATEGORY_LIST = "CHARITY_CATEGORY_LIST"
export const SINGLE_PRODUCT_SUCCESS = "SINGLE_PRODUCT_SUCCESS"
export const SUB_CATEGORY_PRODUCT_SUCCESS = "SUB_CATEGORY_PRODUCT"
export const CHARITY_SEARCH_SUCCESS = "CHARITY_SEARCH_SUCCESS"
export const GET_CHARITY_DETAIL = "GET_CHARITY_DETAIL"
export const CHARITY_TRENDING = "CHARITY_TRENDING"

//SELL
export const ADDL_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS"
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS"
export const SIZE_AVAILABLE_SUCCESS = "SIZE_AVAILABLE_SUCCESS"
export const DELTE_IMAGE_SUCCESS = "DELTE_IMAGE_SUCCESS"


//types for filterData
export const GET_FEEDS_FILTER = "GET_FEEDS_FILTER"
export const priceRange_success = "priceRange_success"
export const charity_selectData = "charity_filterData"
export const search_Name_success = "search_Name_success"
export const subCategory_success = "subCategory_success"
export const charityMOdal_success = "charityMOdal_success"
export const filterMainData_success ="filterMainData_success"
export const after_Filter_WishListMsg = "after_Filter_WishListMsg"
export const Charity_change_feed_show = "Charity_change_feed_show"
export const charityRange_success = "charityPercent_range_success"


