import axios from "axios";
const baseUrl = 'https://api.miraclecloset.com/api/v1/';
const myData = localStorage.getItem("user_data")
const userIDD = JSON.parse(myData)
const userID = userIDD?._id
export const apiCall = (config, dispatch) => {
    return new Promise((resolve, reject) => {
        axios({
            method: config?.method,
            url: config?.url,
            headers: config?.headers,
            data: config?.data || {},
            timeout: 70000,
        })
            .then(async (response) => {

                resolve(response)
            })
            .catch(async (error) => {
                let response = error?.response;
                console.log("response?.status", response?.status)
                if (response?.status === 401) {
                    refreshToken()
                }
                reject(error);
            });
    });
};

const refreshToken = (config, dispatch) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token'), 'Content-Type': 'application/json' },
            url: `${baseUrl}users/refresh`,
            data: {
                _id: userID,
                type: 'user'
            }
        })
            .then(async (response) => {
                resolve(response);
                localStorage.setItem("token", response?.data?.data?.token)
            })
            .catch(async (error) => {
                console.log("error == refreshToken ", error)
            });
    });
};