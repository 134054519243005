import {
    CONTACT_SUCCESS, FAQ_SUCCESS,
    PROFILE_TERMS_SUCCESS, DELETE_USER,
    GET_USER_PROFILE,
    CHANGE_PASSWORD,
    UPDATE_PROFILE,
    MY_CLOSET_SUCCESS,
    MY_ORDER_SUCCESS,
    MY_CART_SUCCESS,
    ADD_CART_SUCCESS,
    CHANGE_EMAIL,
    DELETE_CART_SUCCESS,
    GET_MY_ORDER_DETAIL_SUCCESS,
    GIVE_FEEDBACK_SUCCESS,
    LINK_ACCOUNT
} from "../actions/types";

const INIT_STATE = {
    about: null,
    privacy_policy: null,
    terms: null,
    faq: null,
    contact: null,
    my_closet: null,
    my_order:null,
    my_cart:null,
    del_user: null,
    user_data: null,
    change_pass:null,
    add_cart_data:null,
    change_email:null,
    delete_cart_data:null,
    order_detail:null,
    give_feedback_data:null,
    link_account:null

};

export default (state = INIT_STATE, action) => {
    const { type, payload } = action;
    // console.log("puooi",payload)
    switch (type) {
        case PROFILE_TERMS_SUCCESS:

            return {
                ...state,
                about: payload?.about_us,
                privacy_policy: payload?.privacy_policy,
                terms: payload?.terms,
            };
        case FAQ_SUCCESS:

            return {
                ...state,
                faq: payload
            };

        case CONTACT_SUCCESS:
            return {
                ...state,
                contact: payload?.data,
            }
        case MY_CLOSET_SUCCESS:
            return {
                ...state,
                my_closet: payload?.data,
            }
        case MY_ORDER_SUCCESS:
            return {
                ...state,
                my_order: payload?.data,
            }
        case GET_MY_ORDER_DETAIL_SUCCESS:
            return {
                ...state,
                order_detail: payload?.data,
            }
        case GIVE_FEEDBACK_SUCCESS:
            return {
                ...state,
                give_feedback_data: payload?.data,
            }
        case MY_CART_SUCCESS:
            return {
                ...state,
                my_cart: payload?.data,
            }
        case ADD_CART_SUCCESS:
            return {
                ...state,
                add_cart_data: payload?.data,
            }
        case DELETE_CART_SUCCESS:
            return {
                ...state,
                delete_cart_data: payload?.data,
            }
        case DELETE_USER:
            return {
                ...state,
                del_user: payload?.data,
            }
        case GET_USER_PROFILE:
            return {
                ...state,
                user_data: payload?.status ? payload?.data : null
            };
        case CHANGE_PASSWORD:
            return {
                ...state,
                change_pass: payload
            };
        case UPDATE_PROFILE:
            return {
                ...state,
                user_data: payload.status ? payload?.data : null
            };
        case CHANGE_EMAIL:
            return {
                ...state,
                change_email: payload.status ? payload?.data : null
            };
        case LINK_ACCOUNT:
            return {
                ...state,
                link_account: payload.status ? payload?.data : null
            };

        default:
            return state;
    }
};