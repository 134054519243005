import firebase from "firebase";
import 'firebase/messaging';
import 'firebase/storage'
import { toast } from "react-toastify";
import Wlogo from './assets/image/logo.png'

const firebaseConfig = {
    apiKey: "AIzaSyCK_9YGGa6dOxWvG1Mj1leRw3snjGhugZA",
    authDomain: "miraclecloset-7f873.firebaseapp.com",
    projectId: "miraclecloset-7f873",
    storageBucket: "miraclecloset-7f873.appspot.com",
    messagingSenderId: "469066624449",
    appId: "1:469066624449:web:cf445733073a1529ae9340",
    measurementId: "G-4BLP5D0Q4F"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
export const db = app.firestore();
const storage = app.storage();
export default storage
// const messaging = app.messaging();
const messaging = app.messaging();

const REACT_APP_VAPID_KEY = "BHe-M4yA-yVcS2IDoa81YigMiWURDj4uszEn3dDXbJbMYlrVzF57IrWFZ5rT2hax2YmBVSJY5neXL1h5sB6wXKU"
const publicKey = REACT_APP_VAPID_KEY;

export const getToken = async (setTokenFound) => {
    let currentToken = '';
    try {
        currentToken = await messaging?.getToken({ vapidKey: publicKey });
        if (currentToken) {
            setTokenFound(true);
        } else {
            setTokenFound(false);
        }
    } catch (error) {
        console.log('An error occurred while retrieving token.', error);
    }
    return currentToken;
};

export const onMessageListener = (navRec) => {
    console.log("navec------???????????", navRec)
    new Promise((resolve, reject) => {
        try {
            messaging.onBackgroundMessage((payload) => {
                console.log("at main method onMessageListener", payload)
                toast.success(payload?.notification?.title + ' : ' + payload?.notification?.body, { icon: <img src={Wlogo} style={{ height: 20, width: 30 }} alt='logo' />, autoClose: 3000 })
                if (navRec) {
                    navRec(payload);
                }
            });
        } catch (err) {
            console.error("err firebase.js", err)
        }
    });
}