import { after_Filter_WishListMsg, charityMOdal_success, charityRange_success,  Charity_change_feed_show,  charity_id_only, charity_selectData, filterMainData_success, priceRange_success, subCategory_success } from "../actions/types";

const initState = {
    charityFilterData: [],
    cardShow: false,
    charityValueId: "",
    againFeedDataShow: false,
    filterModal: null,
    subCatDisable: true,
    sliderPriceRange: {
        min: '1',
        max: '20000'
    },
    sliderCharityRange: {
        min: '50',
        max: '100'
    },
    wishListMsg:""
}

export default (state = initState, action) => {
    const { type, payload } = action;
    // console.log("payloashyamm", payload)
    switch (type) {
        case charity_selectData:
            return {
                ...state,
                charityFilterData: payload?.data,
                cardShow: payload?.showData,
                charityValueId: payload?.charityId
            }
        case charityMOdal_success:
            return {
                ...state,
                filterModal: payload,
                againFeedDataShow: payload.feedShow,
             
            }
        case Charity_change_feed_show:
            return {
                ...state,
                againFeedDataShow:payload.feedShow
            }
        case after_Filter_WishListMsg:
            return {
                ...state,
                wishListMsg:payload
            }
        case subCategory_success:
            return {
                ...state,
                subCatDisable: payload.subCate
            }

        case priceRange_success:
            return {
                ...state,
                sliderPriceRange: payload
            }
        case charityRange_success:
            return {
                ...state,
                sliderCharityRange: payload
            }
        default:
            return state;
    }
}