// export const baseUrl = 'http://192.168.0.111:5064/api/v1/';
// export const fileUrl = 'http://192.168.0.13:5064/files/';
export const baseUrl = 'https://api.miraclecloset.com/api/v1/';
export const fileUrl = 'https://api.miraclecloset.com/files/'; 

// test mode
// export const stripeKey = 'pk_test_51LpXgFKS0dRnTf9mNK7mN3v1ePsLN1vRmzteEdbdr1dRvuDLiyZA1YGhMEz2w2lJHwmPUgGQTKeA3oZD94KmtPXj008wt61NOv'
// test mode Client
export const stripeKey = 'pk_live_51MTXgoHbgO8YkMT4UdWcU26rpdYxStOXL7KhkRhV2ClrvhnIIK0RqfCj6rxbx8TzV3NQ8ikPC2vE6gGW7HsghQZe00g7768aC3'

// for feed post share link
export const shareCopyLink = `https://miraclecloset.com/#/product_detail_page`;

const convertToMobilePattern = (phoneNumber) => {
    if (phoneNumber == "" || phoneNumber == undefined || phoneNumber == null) {
        return "";
    }
    var value = phoneNumber.toString();
    let currentValue = value.replace(/[^\d]/g, '');
    let cvLength = currentValue.length;

    // returns: "x", "xx", "xxx"
    if (cvLength < 4) {
        return currentValue;
    }
    // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
    if (cvLength < 7) {
        return "(" + currentValue.slice(0, 3) + ") " + currentValue.slice(3) + "";
    }
    return "(" + currentValue.slice(0, 3) + ") " + currentValue.slice(3, 6) + "-" + currentValue.slice(6, 10);
}
export default convertToMobilePattern;

export const emailValidation = (email) => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
}
export const onlyNumberType = (data) => {
    const re = /^[0-9\b]+$/;
    return re.test(data)
}

