import { combineReducers } from "redux";
import auth from "./auth";
import profile from "./profile";
import feed from "./feed"
import charity from "./charity";
import sell from "./sell";
import charityNonApi from "./charityNonApi"
const root = combineReducers({
    auth,
    profile,
    feed,
    charity,
    sell,
    charityNonApi,
});

export default root;