import { CHARITY_CATEGORY_LIST, CHARITY_LIST_SUCCESS, CHARITY_SEARCH_SUCCESS, CHARITY_TRENDING, GET_CHARITY_DETAIL, SELLER_DETAIL_SUCCESS, SINGLE_PRODUCT_SUCCESS, SUB_CATEGORY_PRODUCT_SUCCESS } from "../actions/types";

const INIT_STATE = {
    search_charity_Data:null,
    allCategoryData: null,
    charity_list: null,
    oneCharity_data:null,
    sub_category_data: null,
    single_product_data:null,
    seller_detail:null,
    trending_data:null
};

export default (state = INIT_STATE, action) => {
    const { type, payload } = action;
    // console.log("puooi", payload)
    switch (type) {
        case CHARITY_CATEGORY_LIST:

            return {
                ...state,
                allCategoryData: payload?.data,

            }
        case CHARITY_LIST_SUCCESS:

            return {
                ...state,
                charity_list: payload?.data,

            }
        case GET_CHARITY_DETAIL:

            return {
                ...state,
                oneCharity_data: payload?.data,

            }
        case CHARITY_TRENDING:

            return {
                ...state,
                trending_data: payload?.data,

            }
        case SUB_CATEGORY_PRODUCT_SUCCESS:

            return {
                ...state,
                sub_category_data: payload?.data,

            }
        case SINGLE_PRODUCT_SUCCESS:

            return {
                ...state,
                single_product_data: payload?.data,

            }
        case SELLER_DETAIL_SUCCESS:

            return {
                ...state,
                seller_detail: payload?.data,

            }
        case CHARITY_SEARCH_SUCCESS:

            return {
                ...state,
                search_charity_Data: payload?.data,

            }

        default:
            return state;
    }
};