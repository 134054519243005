import { apiCall } from "../../api";
import { baseUrl } from "../../constant";
import {
    SEARCH_USER_SUCCESS, GET_FEEDS, GET_PRODUCT_COMMENTS, ADD_COMMENTS, LIKE_FEED_PRODUCT,
    OTHER_USER_DATA, SELLER_ALL_PRODUCT, ADD_TO_WISHLIST, GET_NOTIFICATION, READ_NOTIFICATION,
    GET_MY_SALES, UPDATE_ORDER_STATUS, FOLLOW_UNFOLLOW_USER, GET_ADDRESS, ADD_ADDRESS, DELETE_ADDRESS, UPDATE_ADDRESS, GET_WISHLIST,
    ORDER_PLACE, GET_MY_WALLET, GET_FEEDS_FILTER, SEND_CHAT_NOTIFICATION, SINGLE_PRODUCT_BUY
} from "./types";


export const search_user_name = (data, successCb, failureCallback) => async (dispatch) => {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` },
        url: `${baseUrl}users/search_user`,
        data
    };
    let response = await apiCall(config, dispatch);
    if (response?.data?.status) {
        successCb(response?.data);
    } else {
        failureCallback(response?.data)
    }
    dispatch({
        type: SEARCH_USER_SUCCESS,
        payload: response?.data,
    });

};

// Get Feeds
export const getFeedsData = (data, successCb, failureCb, forFilter = null) => async dispatch => {
    let config = {
        method: 'post',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        url: `${baseUrl}sell/get_feeds`,
        data
    }
    try {
        let response = await apiCall(config, dispatch)
        if (response?.data?.status) {
            successCb(response?.data)
            dispatch({
                type: GET_FEEDS,
                payload: response?.data,
            });
            if (forFilter) {
                dispatch({
                    type: GET_FEEDS_FILTER,
                    payload: response?.data,
                });
            }
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error?.data
            let message = response && response?.data && response?.data?.errors ? response?.data?.errors[0] : response?.message
            failureCb(message)
        }
    }
}

// Get Product Comments
export const getProductComments = (data, successCb, failureCb) => async dispatch => {
    let config = {
        method: 'post',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        url: `${baseUrl}sell/get_product_comments`,
        data
    }
    try {
        let response = await apiCall(config, dispatch)
        if (response?.data?.status) {
            successCb(response?.data)
            dispatch({
                type: GET_PRODUCT_COMMENTS,
                payload: response?.data,
            });
        } else {
            failureCb(response?.data)
        }

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error?.data
            let message = response && response?.data && response?.data?.errors ? response?.data?.errors[0] : response?.message
            failureCb(message)
        }
    }
}

// Add Comments
export const addProductComments = (data, successCb, failureCb) => async dispatch => {
    let config = {
        method: 'post',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        url: `${baseUrl}sell/comment_product`,
        data
    }
    try {
        let response = await apiCall(config, dispatch)
        if (response?.data?.status) {
            successCb(response?.data)
            dispatch({
                type: ADD_COMMENTS,
                payload: response?.data,
            });
        } else {
            failureCb(response?.data)
        }

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error?.data
            let message = response && response?.data && response?.data?.errors ? response?.data?.errors[0] : response?.message
            failureCb(message)
        }
    }
}

// Like Feed post
export const likeFeedProducts = (data, successCb, failureCb) => async dispatch => {
    let config = {
        method: 'post',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        url: `${baseUrl}sell/like_product`,
        data,
    }
    try {
        let response = await apiCall(config, dispatch)
        if (response?.data?.status) {
            successCb(response?.data)
            dispatch({
                type: LIKE_FEED_PRODUCT,
                payload: response?.data,
            });
        } else {
            failureCb(response?.data)
        }
    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error?.data
            let message = response && response?.data && response?.data?.errors ? response?.data?.errors[0] : response?.message
            failureCb(message)
        }
    }
}

// OtherUserDetail
export const otherUserDetail = (data, successCb, failureCb) => async dispatch => {
    let config = {
        method: 'post',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        url: `${baseUrl}users/get_other_user_detail`,
        data
    }
    try {
        let response = await apiCall(config, dispatch)
        if (response?.data?.status) {
            successCb(response?.data)
            dispatch({
                type: OTHER_USER_DATA,
                payload: response?.data,
            });
        } else {
            failureCb(response?.data)
        }

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error?.data
            let message = response && response?.data && response?.data?.errors ? response?.data?.errors[0] : response?.message
            failureCb(message)
        }
    }
}

// Seller All Products
export const sellerAllProduct = (data, successCb, failureCb) => async dispatch => {
    let config = {
        method: 'post',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        url: `${baseUrl}sell/get_seller_all_products`,
        data
    }
    try {
        let response = await apiCall(config, dispatch)
        if (response?.data?.status) {
            successCb(response?.data)
            dispatch({
                type: SELLER_ALL_PRODUCT,
                payload: response?.data,
            });
        } else {
            failureCb(response?.data)
        }

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error?.data
            let message = response && response?.data && response?.data?.errors ? response?.data?.errors[0] : response?.message
            failureCb(message)
        }
    }
}

// Add to Wishlist
export const addToWishlist = (data, successCb, failureCb) => async dispatch => {
    let config = {
        method: 'post',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        url: `${baseUrl}sell/add_to_wishlist`,
        data
    }
    try {
        let response = await apiCall(config, dispatch)
        if (response?.data?.status) {
            successCb(response?.data)
            dispatch({
                type: ADD_TO_WISHLIST,
                payload: response?.data,
            });
        } else {
            failureCb(response?.data)
        }

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error?.data
            let message = response && response?.data && response?.data?.errors ? response?.data?.errors[0] : response?.message
            failureCb(message)
        }
    }
}

// Get Notification
export const getNotification = (successCb, failureCb) => async dispatch => {
    let config = {
        method: 'post',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        url: `${baseUrl}users/get_my_notifications`,
    }
    try {
        let response = await apiCall(config, dispatch)
        if (response?.data?.status) {
            successCb(response?.data)
            dispatch({
                type: GET_NOTIFICATION,
                payload: response?.data,
            });
        } else {
            failureCb(response?.data)
        }

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error?.data
            let message = response && response?.data && response?.data?.errors ? response?.data?.errors[0] : response?.message
            failureCb(message)
        }
    }
}

// Mark read Notification
export const readNotification = (successCb, failureCb) => async dispatch => {
    let config = {
        method: 'post',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        url: `${baseUrl}users/mark_notification_read`,
    }
    try {
        let response = await apiCall(config, dispatch)
        if (response?.data?.status) {
            successCb(response?.data)
            dispatch({
                type: READ_NOTIFICATION,
                payload: response?.data,
            });
        } else {
            failureCb(response?.data)
        }

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error?.data
            let message = response && response?.data && response?.data?.errors ? response?.data?.errors[0] : response?.message
            failureCb(message)
        }
    }
}

// Get My Sales
export const getMySales = (successCb, failureCb) => async dispatch => {
    let config = {
        method: 'post',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        url: `${baseUrl}order/get_my_sales`,
    }
    try {
        let response = await apiCall(config, dispatch)
        if (response?.data?.status) {
            successCb(response?.data)
            dispatch({
                type: GET_MY_SALES,
                payload: response?.data,
            });
        } else {
            failureCb(response?.data)
        }

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error?.data
            let message = response && response?.data && response?.data?.errors ? response?.data?.errors[0] : response?.message
            failureCb(message)
        }
    }
}

// Update Order Status
export const updateOrderStatus = (data, successCb, failureCb) => async dispatch => {
    let config = {
        method: 'post',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        url: `${baseUrl}order/update_order_product_status`,
        data
    }
    try {
        let response = await apiCall(config, dispatch)
        if (response?.data?.status) {
            successCb(response?.data)
            dispatch({
                type: UPDATE_ORDER_STATUS,
                payload: response?.data,
            });
        } else {
            failureCb(response?.data)
        }

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error?.data
            let message = response && response?.data && response?.data?.errors ? response?.data?.errors[0] : response?.message
            failureCb(message)
        }
    }
}

// Follow Unfollow User
export const followUnfollowUser = (data, successCb, failureCb) => async dispatch => {
    let config = {
        method: 'post',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        url: `${baseUrl}users/follow_unfollow_user`,
        data
    }
    try {
        let response = await apiCall(config, dispatch)
        if (response?.data?.status) {
            successCb(response?.data)
            dispatch({
                type: FOLLOW_UNFOLLOW_USER,
                payload: response?.data,
            });
        } else {
            failureCb(response?.data)
        }

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error?.data
            let message = response && response?.data && response?.data?.errors ? response?.data?.errors[0] : response?.message
            failureCb(message)
        }
    }
}

// get Address
export const getAddressList = (successCb, failureCb) => async dispatch => {
    let config = {
        method: 'post',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        url: `${baseUrl}order/get_address`,
    }
    try {
        let response = await apiCall(config, dispatch)
        if (response?.data?.status) {
            successCb(response?.data)
            dispatch({
                type: GET_ADDRESS,
                payload: response?.data,
            });
        } else {
            failureCb(response?.data)
        }

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error?.data
            let message = response && response?.data && response?.data?.errors ? response?.data?.errors[0] : response?.message
            failureCb(message)
        }
    }
}

// Add Address
export const addAddressList = (data, successCb, failureCb) => async dispatch => {
    let config = {
        method: 'post',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        url: `${baseUrl}order/add_address`,
        data
    }
    try {
        let response = await apiCall(config, dispatch)
        if (response?.data?.status) {
            successCb(response?.data)
            dispatch({
                type: ADD_ADDRESS,
                payload: response?.data,
            });
        } else {
            failureCb(response?.data)
        }

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error?.data
            let message = response && response?.data && response?.data?.errors ? response?.data?.errors[0] : response?.message
            failureCb(message)
        }
    }
}

// Delete Address
export const deleteAddress = (data, successCb, failureCb) => async dispatch => {
    let config = {
        method: 'post',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        url: `${baseUrl}order/delete_address`,
        data
    }
    try {
        let response = await apiCall(config, dispatch)
        if (response?.data?.status) {
            successCb(response?.data)
            dispatch({
                type: DELETE_ADDRESS,
                payload: response?.data,
            });
        } else {
            failureCb(response?.data)
        }

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error?.data
            let message = response && response?.data && response?.data?.errors ? response?.data?.errors[0] : response?.message
            failureCb(message)
        }
    }
}

// Update Address
export const updateAddress = (data, successCb, failureCb) => async dispatch => {
    let config = {
        method: 'post',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        url: `${baseUrl}order/update_address`,
        data
    }
    try {
        let response = await apiCall(config, dispatch)
        if (response?.data?.status) {
            successCb(response?.data)
            dispatch({
                type: UPDATE_ADDRESS,
                payload: response?.data,
            });
        } else {
            failureCb(response?.data)
        }

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error?.data
            let message = response && response?.data && response?.data?.errors ? response?.data?.errors[0] : response?.message
            failureCb(message)
        }
    }
}

// get My Wishlist
export const getMyWishlist = (data, successCb, failureCb) => async dispatch => {
    let config = {
        method: 'post',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        url: `${baseUrl}sell/get_my_wishlist`,
        data
    }
    try {
        let response = await apiCall(config, dispatch)
        if (response?.data?.status) {
            successCb(response?.data)
            dispatch({
                type: GET_WISHLIST,
                payload: response?.data,
            });
        } else {
            failureCb(response?.data)
        }

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error?.data
            let message = response && response?.data && response?.data?.errors ? response?.data?.errors[0] : response?.message
            failureCb(message)
        }
    }
}

// Place Order
export const orderPlaceForStripe = (data, successCb, failureCb) => async dispatch => {
    let config = {
        method: 'post',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        url: `${baseUrl}order/place_order`,
        data
    }
    try {
        let response = await apiCall(config, dispatch)
        if (response?.data?.status) {
            successCb(response?.data)
            dispatch({
                type: ORDER_PLACE,
                payload: response?.data,
            });
        } else {
            failureCb(response?.data)
        }

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error?.data
            let message = response && response?.data && response?.data?.errors ? response?.data?.errors[0] : response?.message
            failureCb(message)
        }
    }
}

///place single product
export const buySingleProduct = (data, successCb, failureCb) => async dispatch => {
    let config = {
        method: 'post',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        url: `${baseUrl}order/buy_single_product`,
        data
    }
    try {
        let response = await apiCall(config, dispatch)
        if (response?.data?.status) {
            successCb(response?.data)
            dispatch({
                type: SINGLE_PRODUCT_BUY,
                payload: response?.data,
            });
        } else {
            failureCb(response?.data)
        }

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error?.data
            let message = response && response?.data && response?.data?.errors ? response?.data?.errors[0] : response?.message
            failureCb(message)
        }
    }
}
// get MyWalletInfo
export const getMyWalletInfo = (successCb, failureCb) => async dispatch => {
    let config = {
        method: 'post',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        url: `${baseUrl}order/get_my_wallet_money`,
    }
    try {
        let response = await apiCall(config, dispatch)
        if (response?.data?.status) {
            successCb(response?.data)
            dispatch({
                type: GET_MY_WALLET,
                payload: response?.data,
            });
        } else {
            failureCb(response?.data)
        }

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error?.data
            let message = response && response?.data && response?.data?.errors ? response?.data?.errors[0] : response?.message
            failureCb(message)
        }
    }
}

// Send Chat Notification
export const sendChatNotification = (data, successCb, failureCb) => async dispatch => {
    let config = {
        method: 'post',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        url: `${baseUrl}users/send_chat_notification`,
        data,
    }
    try {
        let response = await apiCall(config, dispatch)
        if (response?.data?.status) {
            successCb(response?.data)
            dispatch({
                type: SEND_CHAT_NOTIFICATION,
                payload: response?.data,
            });
        } else {
            failureCb(response?.data)
        }

    } catch (error) {
        if (typeof failureCb === 'function') {
            let response = error?.data
            let message = response && response?.data && response?.data?.errors ? response?.data?.errors[0] : response?.message
            failureCb(message)
        }
    }
}