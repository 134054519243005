import thunk from "redux-thunk";
import root from '../redux/reducers';
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

const initialState = {};
const middleware = [thunk];

const store = createStore(  root,  initialState,  composeWithDevTools(applyMiddleware(...middleware)));

export default store;