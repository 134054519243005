import React, { lazy, Suspense, useEffect, useState } from 'react'
import { Provider, useDispatch } from "react-redux"
import store from './store'
import Logo from './assets/image/logo.png'
import Wlogo from './assets/image/logo.png'
import "animate.css/animate.min.css";
import { getToken, onMessageListener } from './firebase';
import { toast } from 'react-toastify';
import firebase from 'firebase';
import { getNotification } from './redux/actions/feed'

const AppRouter = lazy(() => import('./Routess'))
function App() {
  const [isTokenFound, setTokenFound] = useState(false);

  useEffect(() => {
    const temp = async () => {
      localStorage.setItem("fcm", await getToken(setTokenFound))
    }
    temp();
    onMessageListener()

  }, [])

  if (!navigator.onLine) {
    toast.error('You are not connected to Internet!')
  }
  if (!navigator.cookieEnabled) {
    toast.error('The browser does not support or is blocking cookies from being set.')
  }

  return (
    <Provider store={store}>
      <Suspense fallback={<div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "100vh" }}>
        <div style={{ display: 'grid', placeItems: 'center' }}>
          <img src={Logo} alt="logo" style={{ height: 300, width: '90%' }} />
        </div>
      </div>}>
        <AppRouter />
      </Suspense>
    </Provider>
  );
}
export default App;